import FooterContainer_BevelScreenComponent from '@wix/thunderbolt-elements/src/components/FooterContainer/viewer/skinComps/BevelScreen/BevelScreen.skin';


const FooterContainer_BevelScreen = {
  component: FooterContainer_BevelScreenComponent
};


export const components = {
  ['FooterContainer_BevelScreen']: FooterContainer_BevelScreen
};

